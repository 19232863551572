<template>
  <div>
    <div class="bg-body-tertiary rounded-3">
    <div class="container-fluid ">
    <header class="d-flex flex-wrap justify-content-between align-items-center my-3">
        <button :disabled="!canEdit"
                class="btn"
                :style="canEdit ? 'background-color: #28a745;color: #fff;' : 'background-color: #bdc3c7;color: #fff;'"
                @click="submitTimeSheet()">Soumettre
        </button>
        <button :disabled="!canCancel"
                class="btn"
                :style="canCancel ? 'background-color: #d35400;color: #fff;' : 'background-color: #bdc3c7;color: #fff;'"
                @click="cancelTimeSheet()">Annuler
        </button>

        <button :disabled="!loaded"
                class="btn"
                :style="loaded ? 'background-color: #d41400;color: #fff;' : 'background-color: #bdc3c7;color: #fff;'"
                @click="$refs.timeSheetPrint.print()">Imprimer
        </button>

        <button v-if="$store.getters.isLogged  && ($store.state.actualUserAdmin || $store.getters.canApproveOther)"
                class="btn"
                :disabled="!canCancel || !$store.getters.canApproveThisUser(actualUser)"
                :style="(canCancel && $store.getters.canApproveThisUser(actualUser))? 'background-color: #f368e0;color: #fff;' : 'background-color: #bdc3c7;color: #fff;'"
                @click="approveTimeSheet()">Approuver
        </button>
        <button v-if="$store.getters.isLogged  && ($store.state.actualUserAdmin || $store.getters.canApproveOther)"
                class="btn"
                :disabled="$store.getters.getWeek.ID <=0 || !$store.getters.canApproveThisUser(actualUser)"
                :style="($store.getters.getWeek.ID >0 && $store.getters.canApproveThisUser(actualUser)) ? 'background-color: #5f27cd;color: #fff;' : 'background-color: #bdc3c7;color: #fff;'"
                @click="refuseTimeSheet()">Refusé
        </button>

        <button v-if="actualWeek!=null && $store.getters.isLogged  && $store.state.actualUserAdmin"
                class="btn"
                style="background-color: #1dd1a1;color: #fff;"
                @click="downloadExcel()">Télécharger le Excel
        </button>

        <button v-if="$store.getters.isLogged && $store.state.actualUserAdmin"
                class="btn"
                :disabled="actualWeek==null"
                :style="actualWeek!==null ? 'background-color: #48dbfb;color: #fff;' : 'background-color: #bdc3c7;color: #fff;'"
                style="background-color: #54a0ff;color: #fff;"
                @click="stateShowModal=true;loadStateWeek()">Liste des états
        </button>

        <button v-if="$store.getters.isLogged && ($store.state.actualUserAdmin || $store.getters.canApproveOther)"
                class="btn"
                :disabled="actualUser==null || !loaded"
                :style="(actualUser!==null && loaded) ? 'background-color: #a29bfe;color: #fff;' : 'background-color: #bdc3c7;color: #fff;'"
                style="background-color: #54a0ff;color: #fff;"
                @click="nextUser()">Prochain
        </button>

        <button v-if="$store.getters.isLogged && ($store.state.actualUserAdmin || $store.getters.canApproveOther)"
                class="btn"
                :disabled="actualUser==null || !loaded"
                :style="(actualUser!==null && loaded) ? 'background-color: #81ecec;color: #fff;' : 'background-color: #bdc3c7;color: #fff;'"
                @click="previousUser()">Précédent
        </button>
      </header>
      <!-- Message refusé -->
      <div class="alert alert-danger" role="alert" v-if="$store.getters.isRefused">
        Refusé : {{ $store.getters.getWeekComment }}
      </div>
      <!-- Résumé -->
      <div class="row mb-3">
        <div class="col-md-4">
          <strong>N° de réf. :</strong> {{ 'TS' + String($store.getters.getWeek.ID).padStart(4, '0') }}
        </div>
        <div class="col-md-4">
          <strong>Semaine :</strong><br>
          <date-picker
              :shortcuts="shortcuts"
              type="week"
              format="YYYY-Www"
              value-type="format"
              name="week"
              @input="loadWeekData"
              v-model="actualWeek"
              class="form-control"
              required
          />
        </div>
        <div class="col-md-4">
          <strong>Employé :</strong>
          <div v-if="($store.state.actualUserAdmin|| $store.getters.canApproveOther)">
            <v-select
                style="max-width: 400px"
                v-model="actualUser"
                @input="loadWeekData"
                :reduce="(option) => option.value"
                :options="$store.getters.getUserOptions"
            />
          </div>
          <div v-else>
            {{$store.getters.getUserTitleByID(actualUser)}}
          </div>

        </div>
      </div>

      <!-- Statut et type -->
      <div class="row mb-3">
        <div class="col-md-4">
          <strong>Statut :</strong> {{ $store.getters.getWeekStatus }}
        </div>
        <div class="col-md-4">
          <strong>Type :</strong> {{ $store.getters.getUserTypeByID(actualUser) }}
        </div>
        <div class="col-md-4">
          <strong>Période :</strong>
          <span v-if="actualWeek != null">{{ firstDay }} au {{ lastDay }}</span>
        </div>
      </div>

        <!-- Temps résumé -->
        <div class="row mb-3">
          <div class="col-md-4">
            <strong>Normal :</strong>
            <span>{{ convertNumberToTimeDuration($store.getters.getRowsNormalSum()) }} Heures</span>
          </div>
          <div class="col-md-4">
            <strong>Suppl. :</strong>
            <span>{{ convertNumberToTimeDuration($store.getters.getRowsOverSum()) }} Heures</span>
          </div>
          <div class="col-md-4">
            <strong>Appel. :</strong>
            <span>{{ convertNumberToTimeDuration($store.getters.getRowsCallSum()) }} Heures</span>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-4">
            <strong>Maladie :</strong>
            <span>{{ convertNumberToTimeDuration($store.getters.getRowsHealtCareSum()) }} Heures</span>
          </div>
          <div class="col-md-4">
            <strong>Vacance. :</strong>
            <span>{{ convertNumberToTimeDuration($store.getters.getRowsVacancySum()) }} Heures</span>
          </div>
          <div class="col-md-4">
            <strong>Total :</strong>
            <span>{{ convertNumberToTimeDuration($store.getters.getRowsTotalSum()) }} Heures</span>
          </div>
        </div>

    </div>
    </div>
    <div class="table-container">
      <div v-if="actualTab===0">
        <header class="header">
          <div class="btn-group" role="group" aria-label="Basic example">

          <!-- Bouton Actualiser -->
          <button
              type="button"
          class="btn btn-primary position-relative"
              @click="loadWeekData"
              :disabled="!canEdit"
              title="Actualiser">
            Actualiser
          </button>
          <!-- Bouton Ajouter une ligne -->
          <button
              type="button"
          class="btn btn-success position-relative"
              @click="addRow"
              :disabled="!canEdit"
              title="Ajouter une ligne">
            Ajouter une ligne
          </button>
          <!-- Bouton Exporter vers Excel -->
          <button
              type="button"
          class="btn btn-primary position-relative"
              @click="toCSV"
              :disabled="$store.getters.getRows.length === 0 "
              title="Exporter vers Excel">
            Exporter vers Excel
          </button>
          <!-- Bouton Supprimer les lignes -->
          <button
              type="button"
          class="btn btn-danger position-relative"
              @click="deleteRows"
              :disabled="!canEdit || $store.getters.getRows.filter(r => r.selected === true).length === 0"
              title="Supprimer les lignes">
            Supprimer les lignes
          </button>
          <button
              type="button"
          class="btn btn-warning position-relative"
              :disabled="actualTab===0"
              @click="actualTab=0"
              title="Voir Temps">
            Temps
          </button>
          <button
              type="button"
              class="btn btn-primary position-relative"
              :disabled="actualTab===1"
              @click="actualTab=1"
              title="Voir Prime">
            Prime
            <span v-if="$store.getters.getStockSheets.length>0" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
              {{$store.getters.getStockSheetsTotal()}}
            </span>
          </button>
          </div>

        </header>

        <!-- Tableau -->
        <div class="table-responsive">
            <table style="margin-bottom: 300px;" class="table table-bordered table-striped">
              <thead class="">

              <tr>
              <th>🗑️</th>
              <th>Type de revenu</th>
              <th style="min-width: 150px">Projet</th>
              <th>Client</th>
              <th>Description du projet</th>
              <th>Article de main-d'œuvre</th>
              <th style="min-width: 350px">Description</th>
              <th>Dim<br> {{ convertNumberToTimeDuration($store.getters.getRowsSumPerDay("sunday", "*")) }}</th>
              <th>Lun<br> {{ convertNumberToTimeDuration($store.getters.getRowsSumPerDay("monday", "*")) }}</th>
              <th>Mar<br> {{ convertNumberToTimeDuration($store.getters.getRowsSumPerDay("Tuesday", "*")) }}</th>
              <th>Mer<br> {{ convertNumberToTimeDuration($store.getters.getRowsSumPerDay("Wednesday", "*")) }}</th>
              <th>Jeu<br> {{ convertNumberToTimeDuration($store.getters.getRowsSumPerDay("Thursday", "*")) }}</th>
              <th>Ven<br> {{ convertNumberToTimeDuration($store.getters.getRowsSumPerDay("Friday", "*")) }}</th>
              <th>Sam<br> {{ convertNumberToTimeDuration($store.getters.getRowsSumPerDay("Saturday", "*")) }}</th>
              <th>Heures cons.</th>
              <th>Factu.</th>
              <th>Approbateur</th>
            </tr>
            </thead>
            <tbody>
            <tr class="edit" v-for="row in $store.getters.getRows" v-bind:key="row.ID">
              <td>
                <input :disabled="!canEditRow(row)" type="checkbox" v-model="row.selected">
              </td>
              <td>
                <div v-if="canEditRow(row)">
                  <v-select
                      style="height: 100%;padding: 0;"
                            v-model="row.rateType"
                            :clearable="false"
                            :reduce="(option) => option.value"
                            :options="$store.getters.getRateTypeOptions"
                            @input="editRow(row)"
                  >
                    <template #option="{ label  }">
                      <p style="margin: 0">{{ label }}</p>
                    </template>
                    <template #selected-option="{ label, value }">
                      <div style="display: flex; align-items: baseline">
                        <strong>{{ value }}</strong>
                      </div>
                    </template>
                  </v-select>
                  <!--Dropdown
                      style="width: 98%;"
                      v-model="row.rateType"
                      @input="editRow(row)"
                      :options="$store.getters.getRateTypeOptions"
                  >
                  </Dropdown-->
                </div>
                <div v-else>
                  {{ row.rateType }}
                </div>
              </td>
              <td>
                <div v-if="canEditRow(row)">
                  <!--Dropdown
                      style="width: 98%;"
                      v-model="row.project"
                      @input="editRow(row)"
                      :options="$store.getters.getProjectOptions"
                  >
                  </Dropdown-->
                  <v-select
                      style="height: 100%;padding: 0;"
                      v-model="row.project"
                      :clearable="false"
                      :reduce="(option) => option.value"
                      :options="$store.getters.getProjectOptions"
                      @input="editRow(row)"
                  >
                    <template #option="{ label  }">
                      <p style="margin: 0">{{ label }}</p>
                    </template>
                    <template #selected-option="{ label, value }">
                      <div style="display: flex; align-items: baseline">
                        <strong>{{ value }}</strong>
                      </div>
                    </template>
                  </v-select>
                </div>
                <div v-else>
                  {{ row.project }}
                </div>
              </td>
              <td>{{ $store.getters.getProjectCustomerByCode(row.project) }}</td>
              <td>{{ $store.getters.getProjectTitleByCode(row.project) }}</td>
              <td>
                <div v-if="canEditRow(row)">
                  <!--Dropdown--
                      style="width: 98%;"
                      v-model="row.mainWorkType"
                      @input="editRow(row)"
                      :options="$store.getters.getMainWorkTypeOptions"
                  >
                  </Dropdown-->
                  <v-select
                      style="height: 100%;padding: 0;"
                            v-model="row.mainWorkType"
                          :clearable="false"
                          :reduce="(option) => option.value"
                            :options="$store.getters.getMainWorkTypeOptions"
                            @input="editRow(row)"
                  >
                    <template #option="{ label  }">
                        <p style="margin: 0">{{ label }}</p>
                    </template>
                    <template #selected-option="{ label, value }">
                      <div style="display: flex; align-items: baseline">
                        <strong>{{ value }}</strong>
                      </div>
                    </template>
                  </v-select>
                </div>
                <div v-else>
                  {{ row.mainWorkType }}
                </div>
              </td>
              <td class="full-td">
                <div v-if="canEditRow(row)">
                <textarea  type="text"
                           rows="4"
                           cols="50"
                       multiple
                       class="full-td-input"
                       :disabled="!canEditRow(row)"
                       @change="editRow(row)"
                       @keydown="editRow(row)"
                       v-model="row.description"/>
                  </div>
                <div v-else>
                  {{row.description}}
                </div>
              </td>
              <td>
                <vue-timepicker name="sunday" :mode-sign="modeSign(row)"
                                :help-label="row.rateType + ' - ' + row.project + ' - ' + row.mainWorkType"
                                v-model.number="row.sunday" :hide-clear-button="!isMobile()" :disabled="!canEditRow(row)"
                                :minute-interval="15" :manual-input="!isMobile()" @input="editRow(row)"
                                @change="editRow(row)"></vue-timepicker>
              </td>
              <td>
                <vue-timepicker name="monday" :mode-sign="modeSign(row)"
                                :help-label="row.rateType + ' - ' + row.project + ' - ' + row.mainWorkType"
                                v-model.number="row.monday" :hide-clear-button="!isMobile()" :disabled="!canEditRow(row)"
                                :minute-interval="15" :manual-input="!isMobile()" @input="editRow(row)"
                                @change="editRow(row)"></vue-timepicker>
              </td>
              <td>
                <vue-timepicker name="monday" :mode-sign="modeSign(row)"
                                :help-label="row.rateType + ' - ' + row.project + ' - ' + row.mainWorkType"
                                v-model.number="row.tuesday" :hide-clear-button="!isMobile()" :disabled="!canEditRow(row)"
                                :minute-interval="15" :manual-input="!isMobile()" @input="editRow(row)"
                                @change="editRow(row)"></vue-timepicker>
              </td>
              <td>
                <vue-timepicker name="monday" :mode-sign="modeSign(row)"
                                :help-label="row.rateType + ' - ' + row.project + ' - ' + row.mainWorkType"
                                v-model.number="row.wednesday" :hide-clear-button="!isMobile()"
                                :disabled="!canEditRow(row)" :minute-interval="15" :manual-input="!isMobile()"
                                @input="editRow(row)" @change="editRow(row)"></vue-timepicker>
              </td>
              <td>
                <vue-timepicker name="monday" :mode-sign="modeSign(row)"
                                :help-label="row.rateType + ' - ' + row.project + ' - ' + row.mainWorkType"
                                v-model.number="row.thursday" :hide-clear-button="!isMobile()"
                                :disabled="!canEditRow(row)" :minute-interval="15" :manual-input="!isMobile()"
                                @input="editRow(row)" @change="editRow(row)"></vue-timepicker>
              </td>
              <td>
                <vue-timepicker name="monday" :mode-sign="modeSign(row)"
                                :help-label="row.rateType + ' - ' + row.project + ' - ' + row.mainWorkType"
                                v-model.number="row.friday" :hide-clear-button="!isMobile()" :disabled="!canEditRow(row)"
                                :minute-interval="15" :manual-input="!isMobile()" @input="editRow(row)"
                                @change="editRow(row)"></vue-timepicker>
              </td>
              <td>
                <vue-timepicker name="monday" :mode-sign="modeSign(row)"
                                :help-label="row.rateType + ' - ' + row.project + ' - ' + row.mainWorkType"
                                v-model.number="row.saturday" :hide-clear-button="!isMobile()"
                                :disabled="!canEditRow(row)" :minute-interval="15" :manual-input="!isMobile()"
                                @input="editRow(row)" @change="editRow(row)"></vue-timepicker>
              </td>
              <td>{{ convertNumberToTimeDuration(parseFloat($store.getters.getRowConsumeSum(row) || 0).toFixed(2)) }}
              </td>
              <td><input :disabled="true" type="checkbox" :checked="isBillable(row)"></td>

              <td>{{ $store.getters.getProjectByCodeApprovedBy(row.project) }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-else>
        <Equipment :actual-tab="actualTab"
                   :actual-user-id="actualUser"
                   :actual-week="actualWeek"
                   :can-edit="canEdit"
                   @sommaireTab="actualTab=0"
                   @loadWeekData="loadWeekData()"
        ></Equipment>
      </div>
      <WeekState :show-modal="stateShowModal"
                 :actual-user="actualUser"
                 :actual-week="actualWeek"
                 :user-list="$store.getters.getUserOptions"
                 :week-state="$store.getters.getStateWeeks"
                 @close="stateShowModal=false"
                 @lockApprovedWeek="lockApprovedWeek"
      ></WeekState>
      <TimePrint
          ref="timeSheetPrint"
          :actual-week="actualWeek"
          :first-day="firstDay"
          :last-day="lastDay"
          :user-name="$store.getters['getUserTitleByID'](actualUser) + ' #' +actualUser"
          :user-print-name="$store.getters['getUserTitleByID'](actualUser) + ' #' +actualUser"
          user-company-logo-url="https://laurentiaexploration.com/wp-content/uploads/2018/04/Laurentia_exploration-1.png"
      >
      </TimePrint>
    </div>
  </div>
</template>

<script>

import "vue-select/dist/vue-select.css";
import arrayToCSV from "@/arrayToCSV";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'debounce';
import Equipment from "./PrimeTable.vue"
import WeekState from "./WeekState.vue"
import TimePrint from "./TimePrint.vue"
import VueTimepicker from "@/components/vue-timepicker.vue";

let debounce = require('debounce');

export default {
  name: 'TimeSheet',
  components: {
    VueTimepicker, DatePicker,Equipment,WeekState,TimePrint/*,VueNumberInput */,
  },
  data() {
    return {
      mainWorkTypeFilteredList:null,
      projectFilteredList:null,
      rateTypeFilteredList:null,
      actualTab : 0,
      loaded : false,
      saving : false,
      loginShowModal : false,
      stateShowModal : false,
      actualUser :  null ,
      actualWeek : null,
      projectColumns:[
        { title: 'Code', data: 'Code' },
        { title: 'Titre', data: 'Title' },
        { title: 'État', data: 'State' },
      ],
      shortcuts: [
        {
          text: "Aujourd'hui",
          onClick() {
            const date = new Date();
            // return a Date
            return date;
          },
        },
        {
          text: 'Sem. passé',
          onClick() {
            const today = new Date();
            const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
            return lastWeek;
          },
        },
      ],
    }
  },
  computed: {

    projectList() {return  Object.assign([],this.$store.state.projects)},
    selectedDateAndUser() {return this.actualWeek!==null && this.actualWeek!=="" && this.actualUser!==null && this.actualUser!==""},
    canApprove() {return this.$store.getters.isLogged && this.$store.getters.canApproveThisUser(this.actualUser)},
    canEdit() {return this.selectedDateAndUser && this.loaded && !this.saving &&this.$store.getters.isEditable},
    canCancel() {return this.selectedDateAndUser && this.loaded && !this.saving && this.$store.getters.isCancellable},
    firstDay(){
      if (this.actualWeek===null || this.actualWeek===""){
        return ""
      }
      const d = this.actualWeek.toString().split("-W")
      const curr = this.getDateOfISOWeek(d[1],d[0])
      const first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
      return new Date(curr.setDate(first)).toISOString().split("T")[0];
    },
    lastDay(){
      if (this.actualWeek===null || this.actualWeek===""){
        return ""
      }
      const d = this.actualWeek.toString().split("-W")
      const curr = this.getDateOfISOWeek(d[1],d[0])
      const first = curr.getDate() - curr.getDay();
      const last = first + 6; // last day is the first day + 6
      return new Date(curr.setDate(last)).toISOString().split("T")[0];
    },
  },
  watch: {
   /* selectedDateAndUser(newValue) {
      if (newValue==true){
       // this.loadWeekData()
      }
    },*/
   /* actualUser(newValue) {
      if (this.actualWeek!=null && this.actualWeek!="" && newValue!=null && newValue!="")
        this.loadWeekData()
    },
    actualWeek(newValue) {
      if (newValue!=null && newValue!=""&& this.actualUser!=null && this.actualUser!="")
        this.loadWeekData()
    },*/
  },
  mounted() {
    /*const u = localStorage.getItem('actualUser')
    if (u!==undefined && u!=null && typeof u === 'string' || u instanceof String){
      this.actualUser = u;
    }*/

    this.login()
    this.loadBaseData()

  },
  methods:{
    getCurrentWeek() {
      const now = new Date();
      // Obtenir l'année
      const year = now.getFullYear();

      // Obtenir le numéro de la semaine
      const startOfYear = new Date(year, 0, 1); // Premier jour de l'année
      const days = Math.floor((now - startOfYear) / (24 * 60 * 60 * 1000)); // Nombre de jours depuis le début de l'année
      const week = Math.ceil((days + startOfYear.getDay() + 1) / 7); // Calcul du numéro de semaine

      // Retourner le résultat au format YYYY-Www
      return `${year}-W${week.toString().padStart(2, '0')}`;
    },
    convertNumberToTimeDuration(number) {
      // Check sign of given number
      let sign = (number >= 0) ? ' ' : '-';
      // Set positive value of number of sign negative
      number = Math.abs(number);
      // Separate the int from the decimal part
      let hour = Math.floor(number) || 0;
      let decpart = number - hour;
      let min = 1 / 60;
      // Round to nearest minute
      decpart = min * Math.round(decpart / min);

      let minute = Math.floor(decpart * 60) + '';
      // Add padding if need
      if (minute.length < 2) {
        minute = '0' + minute;
      }

      // Concate hours and minutes
      const time = sign + hour.toString().padStart(2,'0') + ':' + minute;
      return time
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    modeSign(row){
      switch (row.rateType) {
        case 'BANQUERG':
        case 'BANQUET1/2':
          return "-";
        case 'BANQUE-':
          return "+";
        default:
          return "";
      }
    },
    getDateOfISOWeek(w, y) {
      let simple = new Date(y, 0, 1 + (w - 1) * 7);
      let dow = simple.getDay();
      let ISOWeekStart = simple;
      if (dow <= 4)
        ISOWeekStart.setDate(simple.getDate() - simple.getDay() + 1);
      else
        ISOWeekStart.setDate(simple.getDate() + 8 - simple.getDay());
      return ISOWeekStart;
    },
    canEditRow(row) {
      return this.canEdit && (row.approvedBy===null || row.approvedBy===0)  && row.ID>0
    },
    cancelTimeSheet() {
      if (!this.canCancel || this.$store.getters.getWeek.ID <=0){
        return
      }
      if (confirm('Êtes-vous sûr de annuler votre feuille de temps?')) {
        this.saving=true
        this.$store.dispatch("updateStateTimeSheetWeek", {
          ID : this.$store.getters.getWeek.ID,
          payload :
              {state:"En attente"}
        }).then(
            ()=>{this.saving=false}
        ).catch((err)=>{
          console.log(err)
          this.$toast.error("Une erreur est survenue!")
        })
      }
    },
    submitTimeSheet() {
      if (!this.canEdit || this.$store.getters.getWeek.ID <=0){
        return
      }
      if (confirm('Êtes-vous sûr de soumettre votre feuille de temps?')) {
        this.saving=true
        this.$store.dispatch("updateStateTimeSheetWeek", {
          ID : this.$store.getters.getWeek.ID,
          payload : {
            state:"Soumis",
            approvedBy:null,
          }}).then(
            ()=>{this.saving=false}
        ).catch((err)=>{
          console.log(err)
          this.$toast.error("Une erreur est survenue!")
        })
      }
    },
    approveTimeSheet() {
      if (!this.canCancel || this.$store.getters.getWeek.ID <=0 || !this.$store.getters.canApproveThisUser(this.actualUser)){
        return
      }
      if (confirm('Êtes-vous sûr de approuver cette feuille de temps?')) {
        this.saving=true
        this.$store.dispatch("updateStateTimeSheetWeek", {
          ID : this.$store.getters.getWeek.ID,
          payload : {
            state:"Approuvé",
            comment:"",
            approvedBy:this.$store.getters.loggedUserId
          }}).then(
            ()=>{
              this.saving=false
            }
        ).catch((err)=>{
          console.log(err)
          this.$toast.error("Une erreur est survenue!")
        })
        for (let row of this.$store.getters.getRows) {
          if (row.ID && row.ID>1){
            row.approvedBy = this.$store.getters.loggedUserId
            this.$store.dispatch("updateRow", {ID : row.ID,payload : row}).catch((err)=>{
              console.log(err)
              this.$toast.error("Une erreur est survenue!")
            })
          }
        }
      }
    },
    refuseTimeSheet() {
      if (this.$store.getters.getWeek.ID <=0 || !this.$store.getters.canApproveThisUser(this.actualUser)){
        return
      }
      if (confirm('Êtes-vous sûr de refusé cette feuille de temps?')) {
        this.saving=true
        this.$store.dispatch("updateStateTimeSheetWeek", {ID : this.$store.getters.getWeek.ID,payload : {
            state:"Refusé",
            comment:prompt("Raison du refus:", ""),
            approvedBy:this.$store.getters.loggedUserId}}).then(
            ()=>{
              this.saving=false
            }
        ).catch((err)=>{
          console.log(err)
          this.$toast.error("Une erreur est survenue!")
        })
        for (let row of this.$store.getters.getRows) {
          if (row.ID && row.ID>1){
            row.approvedBy = null
            this.$store.dispatch("updateRow", {ID : row.ID,payload : row}).catch((err)=>{
              console.log(err)
              this.$toast.error("Une erreur est survenue!")
            })
          }
        }
      }
    },
    isNanDay(row, day){
      if (row[day]=="" || isNaN(row[day])) {
        row[day]=0;
      }
      return row
    },
    editRow: debounce(function(row) {
      if (this.canEditRow(row) && row.ID!=-1){
       /* if (e!==undefined && e.target!==undefined && e.target.validity!==undefined) {
          switch (row.rateType) {
            case 'BANQUERG':
            case 'BANQUET1/2':
              e.target.validity.valid || (e.target.value=e.target.previousValue)
              break
            case 'BANQUET-':
              e.target.validity.valid || (e.target.value=e.target.previousValue)
              break
          }
        }*/
       // this.saving=true
        row = this.isNanDay(row,"sunday")
        row = this.isNanDay(row,"monday")
        row = this.isNanDay(row,"tuesday")
        row = this.isNanDay(row,"wednesday")
        row = this.isNanDay(row,"thursday")
        row = this.isNanDay(row,"friday")
        row = this.isNanDay(row,"saturday")

        this.$store.dispatch("updateRow", {ID : row.ID,payload : row}).then(
            ()=>{this.saving=false}
        ).catch((err)=>{
          console.log(err)
          this.$toast.error("Une erreur est survenue!")
        })
      }
    }, 100),
    addRow() {
      if (!this.canEdit) {
        return;
      }
      let user = this.$store.getters.getUserByCode(this.actualUser)
      let mainWorkDefault = this.$store.getters.getMainWorkTypeById(user.MainWorkTypeID)
      let rateTypeDefault = this.$store.getters.getRateTypeById(user.RateTypeID)

      this.saving=true
      this.$store.dispatch("addNewRow",{
        week_number:this.actualWeek,
        user:this.actualUser,
        project:"",
        mainWorkType: mainWorkDefault.Code ? mainWorkDefault.Code : "",
        rateType: rateTypeDefault.Code ? rateTypeDefault.Code  : "RG"
      }).then(
          ()=>{this.saving=false}
      )
    },
    toCSV(){
      let csv = arrayToCSV({ data:this.$store.getters.getRows})
      let element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
      element.setAttribute('download', this.actualUser+" "+this.actualWeek+".csv");
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    nextUser(){
      if (this.actualUser==null && this.actualUser=="") {
        return
      }
      const index = this.$store.getters.getUserOptions.findIndex(x=>x.value==this.actualUser);
      if(index >= 0 && index < this.$store.getters.getUserOptions.length - 1) {
        this.actualUser = this.$store.getters.getUserOptions[index + 1].value
        this.loadWeekData()
      }

    },
    previousUser(){
      if (this.actualUser==null && this.actualUser=="") {
        return
      }
      const index = this.$store.getters.getUserOptions.findIndex(x=>x.value==this.actualUser);
      if(index >= 1) {
        this.actualUser = this.$store.getters.getUserOptions[index - 1].value
        this.loadWeekData()
      }

    },
    openSupport() {
      window.open('https://ticket.rousseaufournier.com/', '_blank');
    },
    deleteRows(){
      if (!this.canEdit) {
        return;
      }
      if (this.$store.getters.getRows.length===0){
        return;
      }
      if (confirm('Êtes-vous sûr de supprimer ces lignes?')){
        this.$store.getters.getRows.filter(r=>r.selected===true).forEach((row)=> {
          if (row.selected && (row.approvedBy==null || row.approvedBy<=0) ) {
            this.saving=true
            this.$store.dispatch('deleteRow', {ID:row.ID,payload : row}).then(
                ()=>{this.saving=false}
            ).catch((err)=>{
              console.log(err)
              this.$toast.error("Une erreur est survenue!")
            })
          }
        })
      }
    },
    loadBaseData() {
      this.$store.dispatch('fetchProjects').then(
      ()=>{
      this.$store.dispatch('fetchMainWorkType').then(
      ()=>{
      this.$store.dispatch('fetchRateTypes').then(
      ()=>{
        this.$store.dispatch('fetchUser').then(
      ()=>{
        this.$store.dispatch('fetchStock').then(
      ()=>{
        this.actualWeek = this.getCurrentWeek()
        this.loadWeekData()
      })
      })
      })
      })
      })

    },
    loadStateWeek() {
      if (this.actualWeek!=null && this.actualWeek!="")
      {
        if (this.$store.getters.isLogged) {
          let payload = {
            weekNumber : this.actualWeek,
          }
          this.$store.dispatch("fetchStateWeek",payload).then()
        }
      }
    },
    loadWeekData(){
      this.loadStateWeek()
      if (this.selectedDateAndUser) {
        console.log("Loading..")
        this.loaded=false
        let payload = {
          code : this.actualUser,
          weekNumber : this.actualWeek,
        }
        //localStorage.setItem('actualUser',this.actualUser)
        this.$store.dispatch("fetchTimeSheetWeek",payload).then(
            ()=>{
              this.$store.dispatch("fetchTimeSheet",payload).then(
                  ()=>{
                    this.$store.dispatch("fetchStockSheet",payload).then(
                        ()=>{
                          this.loaded=true
                        }
                    )
                  }
              )
            }
        )


      }
    },
    isBillable(row){
      switch (row.rateType) {
        case 'BANQUERG':
        case 'RG':
        case 'TFORMATION ADM':
        case 'TTRANSPORT ADM':
        case 'BANQUET1/2':
        case 'T1/2':
        case 'T2':
          return true;
        default:
          return false;
      }
    },
    login(e){
      //TODO
      this.$store.dispatch("login",e).then((data)=>{
        this.actualUser= data.data.Data.ID;
        this.$store.dispatch("fetchStateWeek", {weekNumber:this.actualWeek})
        this.loginShowModal=false
      })
    },
    disconnect(){
      //TODO
      this.$store.dispatch("disconnect")
    },
    lockApprovedWeek(){
        if (confirm('Êtes-vous sûr de transféré toutes les feuilles de temps approuvé!?')) {
            this.$store.dispatch("lockStateWeek", {weekNumber: this.actualWeek}).then(() => {
                    this.downloadExcel()
                }
            )
        }
    },
    downloadExcel(){
      window.open(
          '/api/v1/Nethris/'+this.actualWeek + "/"+this.$store.state.actualUserId,
          '_blank' // <- This is what makes it open in a new window.
      );
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>





.summary-row div {
  flex: 1;
}

.summary-row div input {
  width: 80%;
}



.time-summary div {
  flex: 1;
  text-align: center;
}


@keyframes blinkingText{
  0%{     color: red;    }
  49%{    color: red; }
  60%{    color: transparent; }
  99%{    color:transparent;  }
  100%{   color: red;    }
}

.full-td {
  position: relative;
}

.full-td-input{
  position: absolute;
  display: block;
  top:0;
  left:0;
  margin: 0;
  height: 100%;
  width: 100%;
  border: none;
  padding: 10px;
  box-sizing: border-box;
  resize: none;
}
.table > tbody > tr > td {
  vertical-align: middle;
}
td.tall {
  height: 100px;
  padding: 0;
}

input.tall {
  margin: 0px;
  height: 100%;
  display: inline-block;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  resize: none;
}
</style>



