/*=========================================================================================
Item Name: Sharepoint Wiki
Author: Nicolas Fournier
Author URL: https://rousseaufournier.com/
==========================================================================================*/

const getters = {
  isLogged:  state => state.actualUserId>0,
  isAdmin:  state => state.user.admin,
  loggedUser:  state => state.actualUser,
  loggedUserId:  state => state.actualUserId,
  getStateWeeks:  state => state.stateWeeks,
  getWeek:  state => state.week,
  getWeekStatus:  (state,getters)  => state.week.state + (state.week.approvedBy!==null ? " par " +getters.getUserTitleByID(state.week.approvedBy) : ""),
  getWeekComment:  (state,getters) => state.week.comment + (state.week.approvedBy!==null ? " par " + getters.getUserTitleByID(state.week.approvedBy)  : ""),
  isEditable:  state => state.week.state==="En attente" || state.week.state==="" || state.week.state==="Refusé",
  isRefused:  state => state.week.state==="Refusé",
  isCancellable:  state => state.week.state==="Soumis" || state.week.state==="",
  getWeekApprovedBy:  state => state.week.approvedBy,
  getRows:  state => state.rows,
  getRowsSum: state =>(rateTypeCode) => {
    let total = 0.0
    for (const row of state.rows) {
      if (rateTypeCode == row.rateType || rateTypeCode == undefined || rateTypeCode=="" || rateTypeCode=="*") {
        total+=row.monday
        total+=row.saturday
        total+=row.sunday
        total+=row.thursday
        total+=row.tuesday
        total+=row.wednesday
        total+=row.friday
        total+=row.consume
      }
    }
    return total
  },
  getRowsSumPerDay: (state, getters) => (day,rateTypeCode) => {
    let total = 0.0
    for (const row of state.rows) {
      if (rateTypeCode == row.rateType || rateTypeCode == undefined || rateTypeCode=="" || rateTypeCode=="*") {
        total+=getters.getRowConsumeSum(row,day)//row[day.toString().toLowerCase()]
      }
    }
    return parseFloat(total).toFixed(2)
  },
  getRowsTotalSum: (state,getters) => () => {
    let total = 0.0
    total +=  parseFloat(getters.getRowsNormalSum())
    total +=  parseFloat(getters.getRowsOverSum())
    total +=  parseFloat(getters.getRowsHealtCareSum())
    total +=  parseFloat(getters.getRowsCallSum())
    total +=  parseFloat(getters.getRowsVacancySum())
    return parseFloat(total).toFixed(2)
  },
  getRowConsumeSum : ()=>(row,day) => {
    let total = 0.0
    switch (row.rateType) {
      default:
      case 'VACANCES':
      case 'CNESST':
      case 'RG':
      case 'MALADIE':
      case 'T1/2':
      case 'CALL':
      case 'T2':
        if (day===undefined || day =="") {
          total += (row.sunday+row.monday+row.tuesday+row.wednesday+row.thursday+row.friday+row.saturday)
        } else {
          total += (row[day.toString().toLowerCase()])
        }
        break
      case 'ABSENCE':

    }
    return total
  },
  getRowsNormalSum: state => () => {
    let total = 0.0
    for (const row of state.rows) {
      switch (row.rateType) {
        case 'RG':
        /*case 'MALADIE':*/
          total += (row.sunday+row.monday+row.tuesday+row.wednesday+row.thursday+row.friday+row.saturday)
          break
        default:

      }
    }
    return parseFloat(total).toFixed(2)
  },
  getRowsCallSum: state => () => {
    let total = 0.0
    for (const row of state.rows) {
      switch (row.rateType) {
        case 'CALL':
          total += (row.sunday+row.monday+row.tuesday+row.wednesday+row.thursday+row.friday+row.saturday)
          break
        default:

      }
    }
    return parseFloat(total).toFixed(2)
  },
  getRowsHealtCareSum: state => () => {
    let total = 0.0
    for (const row of state.rows) {
      switch (row.rateType) {
        case 'MALADIE':
          total += (row.sunday+row.monday+row.tuesday+row.wednesday+row.thursday+row.friday+row.saturday)
          break
        default:

      }
    }
    return parseFloat(total).toFixed(2)
  },
  getRowsVacancySum: state => () => {
    let total = 0.0
    for (const row of state.rows) {
      switch (row.rateType) {
        case 'VACANCES':
          total += (row.sunday+row.monday+row.tuesday+row.wednesday+row.thursday+row.friday+row.saturday)
          break
        default:

      }
    }
    return parseFloat(total).toFixed(2)
  },
  getRowsOverSum: state =>  () =>{
    let total = 0.0
    for (const row of state.rows) {
      switch (row.rateType) {
        case 'T1/2':
          total += (row.sunday+row.monday+row.tuesday+row.wednesday+row.thursday+row.friday+row.saturday)
          break
        default:

      }
    }
    return parseFloat(total).toFixed(2)
  },
  getStockSheets:  state => state.stockSheets,
  getStockSheetsSum: state =>(rateTypeCode) => {
    let total = 0.0
    for (const row of state.stockSheets) {
      if (rateTypeCode == row.rateType || rateTypeCode == undefined || rateTypeCode=="" || rateTypeCode=="*") {
        total+=row.monday
        total+=row.saturday
        total+=row.sunday
        total+=row.thursday
        total+=row.tuesday
        total+=row.wednesday
        total+=row.friday
        total+=row.consume
      }
    }
    return total
  },
  getStockSheetsTotal: state =>() => {
    let total = 0.0
    for (const row of state.stockSheets) {
      total+=row.monday
        total+=row.saturday
        total+=row.sunday
        total+=row.thursday
        total+=row.tuesday
        total+=row.wednesday
        total+=row.friday
    }
    return total
  },
  getUsers:  state => state.users,
  getUserOptions:  state => state.users.map(v => ({label: v.Title + " (" + v.Code + ")", value: v.ID })),
  getUserByCode: state => (ID) => state.users.find(v => v.ID == ID),
  getUserTitleByID: state => (ID) =>{
    let u = state.users.find(v => v.ID == ID)
    if (u!==undefined && u.Title!==undefined )
      return u.Title + " (" + u.Code + ")"
    return ""
  },
  getUserById: state => (id) => state.users.find(v => v.ID == id),
  getUserTypeByID: state => (ID) => {
    let u = state.users.find(v => v.ID == ID)
    if (u!==undefined && u.ID>0) {
      return u.TypeTS ? u.TypeTS : ""
    }
    return ""
  },
  canApproveThisUser: state => (ID) => {
    //TODOD
    if (state.week.state==="Transféré") {
      return false
    }
    let u = state.users.find(v => v.ID === ID)

    if (u!==undefined && u.ApprovedByUserID>0 && state.actualUserId>=0) {
      return u.ApprovedByUserID===state.actualUserId
    }
    return false
  },
  canApproveOther: state => {
    return state.users.length>1;
  },
  getMainWorkTypes:  state => state.mainWorkTypes,
  getMainWorkTypeOptions:  state => state.mainWorkTypes.filter(x=>(x.Disable===false)).map(v => ({label:  v.Code + " " + v.Title, value: v.Code })),
  getMainWorkTypeByCode: state => (code) => state.mainWorkTypes.find(v => v.Code == code),
  getMainWorkTypeById: state => (ID) => state.mainWorkTypes.find(v => v.ID == ID),
  getRateTypes:  state => state.rateTypes,
  getRateTypeOptions:  state => state.rateTypes.filter(x=>(x.Disable===false)).map(v => ({label:   v.Code + " " + v.Title, value: v.Code })),
  getRateTypeByCode: state => (code) => state.rateTypes.find(v => v.Code == code),
  getRateTypeById: state => (ID) => state.rateTypes.find(v => v.ID == ID),
  getProjects:  state => state.projects,
  getProjectOptions:  state => state.projects.filter(x => x.State!=="Fermé").map(v => ({...v,label:   v.Title + " (" + v.Code + ")"  +  " [" + v.Customer + "]", value: v.Code })),
  getProjectByCode: state => (code) => state.projects.find(v => v.Code == code),
  getProjectByCodeApprovedBy: state => (code) => {
    let p = state.projects.find(v => v.Code == code)
    if (p!==undefined && p.ApprobateurId!==undefined && p.ApprobateurId>0) {
      let u = state.users.find(v => v.ID == p.ApprobateurId)
      return u ? u.Title : ""
    }
    return ""
  },
  getProjectTitleByCode: state => (code) => {
    let p = state.projects.find(v => v.Code == code)
    if (p!==undefined && p.Title!==undefined )
        return p.Title + " (" + p.Code + ")"
    return ""
  },
  getProjectCustomerByCode: state => (code) => {
    let p = state.projects.find(v => v.Code == code)
    if (p!==undefined && p.Customer!==undefined )
        return p.Customer + ""
    return ""
  },
  getStocks:  state => state.stocks,
  getStockOptions:  state => state.stocks.filter(x=>(x.Disable===false)).map(v => ({...v,name:  v.StockCode,label: v.StockCode + " : "+  v.Description, value: v.StockCode  })),
  getStockByStockID: state => (StockCode) => state.stocks.find(v => v.StockCode == StockCode),
  getStockUnitByStockCode: state => (StockCode) => {
    let p = state.stocks.find(v => v.StockCode == StockCode)
    if (p!==undefined && p.Unit!==undefined )
      return p.Unit
    return ""
  },
}

export default getters
